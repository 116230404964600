<template>
  <div id="donations" class="donations">
    <div class="donations__body">
      <div class="donations__title">Faça o seu donativo</div>
      <div class="donations__subtitle">
        <span class="donations__subtitle--top">
          A cada estrela preenchida, um desejo é realizado. Assim, estrela a
          estrela... estamos mais próximos de transformar vidas, de realizar
          desejos!
          <strong>
            A realização de um desejo promove uma esperança sem limites e pode
            mudar a perspetiva da criança ao enfrentar a sua doença, tendo a
            capacidade, muitas vezes, de ser o ponto de viragem na forma como a
            criança e a família encaram a situação que estão a viver.
          </strong>
        </span>
        <span class="donations__subtitle--bottom">
          Juntos, a transformar vidas, um desejo de cada vez.
        </span>
      </div>
      <div class="donations__button-container">
        <button
          class="donations__button-container__button"
          @click="showModal(5)"
        >
          5€
        </button>
        <button
          class="donations__button-container__button"
          @click="showModal(10)"
        >
          10€
        </button>
        <button
          class="donations__button-container__button"
          @click="showModal(15)"
        >
          15€
        </button>
        <button
          class="donations__button-container__button"
          @click="showModal(1)"
        >
          Outro
        </button>
      </div>
    </div>
    <modal
      v-if="isModalOpen"
      v-body-scroll-lock="isModalOpen"
      :donationValue="donationValue"
      @close="closeModal"
    />
  </div>
</template>

<script>
import modal from "../components/modal/modal-donations.vue";
export default {
  name: "stardonations",

  components: {
    modal
  },
  data() {
    return {
      isModalOpen: false,
      donationValue: 0
    };
  },
  methods: {
    showModal(value) {
      this.donationValue = value;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    }
  }
};
</script>

<style scoped lang="scss">
.donations {
  &__body {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 419px;

    @media (min-width: $breakpoint-sm) {
      margin-top: 630px;
    }

    @media (min-width: $breakpoint-md) {
      margin-top: 680px;
    }

    @media (min-width: $breakpoint-lg) {
      margin-top: 125px;
    }
  }

  &__title {
    font-family: "droid_serifbold";
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 24px;
    color: $color-make-a-wish-blue;

    @media (min-width: $breakpoint-lg) {
      font-size: 38px;
      line-height: 44px;
      margin-bottom: 48px;
    }
  }

  &__subtitle {
    font-family: "latoregular";
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    line-height: 16.8px;
    color: $color-make-a-wish-dark-grey;
    padding: 0 47px;
    margin-bottom: 32px;

    @media (min-width: $breakpoint-sm) {
      font-size: 17px;
      line-height: 22px;
    }

    @media (min-width: $breakpoint-lg) {
      padding: 0 400px;
      margin-bottom: 68px;
    }

    &--top {
      margin-bottom: 16px;
    }

    &--bottom {
      font-weight: bold;
    }
  }

  &__button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-self: center;
    gap: 24px;

    @media (min-width: $breakpoint-lg) {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
    }

    &__button {
      width: 99px;
      height: 36px;
      font-family: "latobold";
      font-size: 17px;
      line-height: 20px;
      text-align: center;
      color: $color-white;
      background: $color-make-a-wish-teal;
      box-shadow: 1px 4px 4px rgba(0, 87, 184, 0.3);
      border-radius: 16px;
      border: 0;

      @media (min-width: $breakpoint-xxs) {
        width: 122px;
        height: 46px;
      }

      @media (min-width: $breakpoint-sm) {
        font-size: 24px;
        line-height: 29px;
      }

      @media (min-width: $breakpoint-lg) {
        width: 188px;
        height: 61px;
      }

      &:hover {
        background-color: $color-make-a-wish-yellow;
      }
    }
  }
}
</style>
