<template>
  <div class="modal-donations">
    <div class="modal-donations__background"></div>
    <div class="modal-donations__content">
      <img
        class="modal-donations__close-button"
        src="/images/buttons/close-button.svg"
        @click="close"
        alt=""
      />
      <div class="modal-donations__title">Preencha os seus dados</div>

      <div class="modal-donations__content--fields">
        <form @submit.prevent="sendDonation">
          <div class="modal-donations__content--fields">
            <label for="firstName" class="form__label required-sign"
              >Nome Completo</label
            >
            <input
              type="text"
              v-model="user.firstName"
              placeholder="Nome Completo"
              id="firstName"
              name="firstName"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.user.firstName.$error }"
            />
            <div
              v-if="submitted && !$v.user.firstName.error"
              class="invalid-feedback"
            >
              <span v-if="!$v.user.firstName.required"
                >Nome Completo é um campo obrigatório.</span
              >
              <span
                v-if="
                  !$v.user.firstName.diacriticValidation &&
                    $v.user.firstName.required
                "
                >Nome inserido não é invalido.</span
              >
            </div>
          </div>
          <div class="modal-donations__content--fields">
            <label for="email" class="form__label required-sign">E-mail</label>
            <input
              type="email"
              v-model="user.email"
              placeholder="E-mail"
              id="email"
              name="email"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.user.email.$error }"
            />
            <div
              v-if="submitted && $v.user.email.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.user.email.required"
                >E-mail é um campo obrigatório.</span
              >
              <span v-if="!$v.user.email.email"
                >Email inserido não é invalido.</span
              >
            </div>
          </div>
          <div class="modal-donations__content--fields">
            <label for="nif" placeholder="Numero de Contribuinte">NIF</label>
            <input
              type="text"
              placeholder="Numero de contribuinte"
              v-model="user.nif"
              id="nif"
              name="nif"
              class="form-control"
              maxlength="9"
            />
          </div>
          <div class="modal-donations__content--fields">
            <label for="address">Morada</label>
            <input
              type="text"
              v-model="user.address"
              placeholder="Morada Fiscal"
              id="address"
              name="address"
              class="form-control"
            />
          </div>
          <div class="modal-donations__content--fields">
            <label for="zipCode">Código Postal</label>
            <input
              type="text"
              v-model="user.zipCode"
              placeholder="Codigo Postal Ex: 2xxx-1xx"
              id="zipCode"
              name="zipCode"
              class="form-control"
              pattern="[0-9]{4}-[0-9]{3}"
            />
          </div>
          <div class="modal-donations__content--fields">
            <label for="phone" class="form__label required-sign"
              >Contacto Telefónico</label
            >
            <MazPhoneNumberInput
              v-model="user.phone"
              id="phone"
              name="phone"
              default-country-code="PT"
              :translations="{
                countrySelectorLabel: 'País',
                phoneNumberLabel: 'Contacto Telefónico',
                placeholder: 'País'
              }"
              :class="{ 'is-invalid': submitted && $v.user.phone.$error }"
              @update="phoneNumberValidation = $event"
              @change="
                validatePhoneNumber(phoneNumberValidation.nationalNumber)
              "
            />
            <div
              v-if="submitted && !$v.user.phone.error"
              class="invalid-feedback"
            >
              <span v-if="!$v.user.phone.required"
                >Numero de Telefone é um campo obrigatório.</span
              >
            </div>
          </div>
          <div class="modal-donations__content--fields">
            <label for="donation">Preencha com o valor que pretende doar</label>
            <currency-input
              id="donation"
              name="donation"
              v-model="user.donation"
              class="form-control"
              :disabled="donationValue > 1"
            />
          </div>
          <div class="modal-donations__terms">
            <input
              v-model="user.terms"
              @change="$v.user.terms.$touch()"
              type="checkbox"
              value="foo"
              :class="{
                required: submitted && $v.user.terms.$error
              }"
            />
            <span
              :class="{
                required: submitted && $v.user.terms.$error
              }"
            >
              Li e aceito com os
              <a href="/pdf/PrivacyNote.pdf" target="_blank"
                >Termos e Condições
              </a>
              da Make-A-Wish Portugal.</span
            >
          </div>
          <div class="form-group">
            <button class="modal-donations__button" type="submit">
              Continuar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, email, sameAs } from "vuelidate/lib/validators";

export default {
  name: "Modal",
  data() {
    return {
      termsSelected: false,
      submitted: false,
      diacriticValidationError: false,
      phoneValid: false,
      user: {
        firstName: "",
        email: "",
        nif: null,
        address: "",
        zipCode: "",
        phone: "",
        country: "",
        donation: this.donationValue
      },
      phoneNumber: null,
      phoneNumberValidation: {},
      phoneNumberError: false
    };
  },

  validations: {
    user: {
      firstName: {
        required,
        diacriticValidation: function diacriticValidation(value) {
          const validationData = /[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/.test(
            value
          );
          this.diacriticValidationError = validationData;

          return validationData;
        }
      },
      email: { required, email },
      terms: {
        selected: sameAs(() => true)
      },
      phone: {
        required,
        phoneValidation: function phoneValidation() {
          return this.phoneNumberValidation.isValid;
        }
      }
    }
  },

  props: {
    donationValue: {
      type: Number,
      default: 0,
      required: false
    }
  },

  methods: {
    ...mapActions({
      postCheckout: "checkout/postCheckout"
    }),
    validatePhoneNumber(number) {
      if (number.length > 4 && !this.phoneNumberValidation.isValid) {
        this.phoneNumberError = true;
      } else {
        this.phoneNumberError = false;
      }
    },
    getExpirationDate() {
      //Expiration Date for easypay checkout, Current day + 2
      let d = new Date();
      d.setDate(d.getDate() + 2);
      return (
        d.getFullYear() +
        "-" +
        ("00" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + d.getDate()).slice(-2) +
        " " +
        ("00" + d.getHours()).slice(-2) +
        ":" +
        ("00" + d.getMinutes()).slice(-2)
      );
    },
    close() {
      this.$emit("close");
    },
    sendDonation() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.postCheckout(this.setCheckoutData).then(() => {
        this.$router.push("/easypay");
      });
    },
    getRandomInt(maxValue) {
      return Math.floor(Math.random() * Math.floor(maxValue));
    }
  },
  computed: {
    ...mapGetters({
      getMainConfig: "partner/getMainConfig"
    }),
    setConfigLogoUrl() {
      return this.getMainConfig.partner.image;
    },
    setConfigPartnerId() {
      return this.getMainConfig.partner.id;
    },
    setPhoneIndicative() {
      return "+" + this.phoneNumberValidation.countryCallingCode;
    },
    setCheckoutData() {
      const checkoutData = {
        partnerId: this.setConfigPartnerId,
        customer: {
          name: this.user.firstName,
          email: this.user.email,
          phone: this.phoneNumberValidation.nationalNumber,
          phone_indicative: this.setPhoneIndicative,
          fiscal_number: this.user.nif
        },
        order: {
          key: this.getMainConfig.partner.id.toString(),
          value: this.user.donation,
          items: [
            {
              value: this.user.donation
            }
          ]
        },
        config: {
          logo_url: this.setConfigLogoUrl
        }
      };
      console.log("SetCheckoutData", checkoutData);
      return checkoutData;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-donations {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  overflow: scroll;

  @media (min-width: $breakpoint-md) {
    overflow: visible;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 87, 184, 0.5);
    z-index: 0;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 100%;
    padding: 30px 27px;
    display: block;
    background-color: $color-white;

    @media (min-width: $breakpoint-md) {
      min-height: unset;
      position: relative;
      padding: 25px 100px;
      border-radius: 1px;
    }

    @media (min-width: $breakpoint-xl) {
      padding: 25px 135px;
    }

    &--error {
      display: none;
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: $color-make-a-wish-red;

      &.active {
        display: block;
      }
    }

    &--fields {
      display: flex;
      flex-direction: column;

      input {
        width: 100%;
        height: 28px;
        font-size: 12px;
        border: 0.5px solid #c4c4c4;
        box-sizing: border-box;
        border-radius: 4px;
        @media (min-width: $breakpoint-xxs) {
          height: 32px;
        }
        @media (min-width: $breakpoint-lg) {
          font-size: 14px;
        }
        @media (min-width: $breakpoint-xl) {
          height: 36px;
          font-size: 16px;
        }
        &.disabled {
          pointer-events: none;
          background: #dddddd;
        }
      }
      label {
        text-align: left;
        font-family: "latoregular";
        font-size: 14px;
        line-height: 16px;
        margin: 20px 0 4px;
        color: $color-make-a-wish-blue;
        @media (min-width: $breakpoint-lg) {
          margin: 10px 0 4px;
          font-size: 16px;
          line-height: 18px;
        }
        @media (min-width: $breakpoint-xl) {
          margin: 20px 0 4px;
        }
      }
      .required-sign:after {
        content: "*";
        font-size: 16px;
        color: $color-make-a-wish-red;
      }
    }
  }

  &__title {
    font-family: "droid_serifbold";
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    color: $color-make-a-wish-blue;
    @media (min-width: $breakpoint-xxs) {
      font-size: 24px;
      line-height: 28px;
    }
    @media (min-width: $breakpoint-xxs) and (max-height: 667px) {
      font-size: 23px;
      line-height: 22px;
    }

    @media (min-width: $breakpoint-lg) {
      font-size: 32px;
      line-height: 26px;
    }
    @media (min-width: $breakpoint-xl) {
      font-size: 38px;
      line-height: 32px;
    }
  }

  &__terms {
    display: flex;
    align-items: flex-start;
    margin: 15px 0 20px;
    @media (min-width: $breakpoint-lg) {
      margin: 10px 0;
    }
    input {
      width: 24px;
      height: 18px;
      &.required {
        outline: none;
        border-color: $color-make-a-wish-red;
        box-shadow: 0 0 10px $color-make-a-wish-red;
      }
    }
    span {
      font-family: "latoregular";
      max-width: 400px;
      font-size: 13px;
      line-height: 12px;
      margin-left: 5px;
      padding-top: 2px;
      @media (min-width: $breakpoint-xxs) {
        font-size: 14px;
        line-height: 17px;
      }
      &.required {
        color: $color-make-a-wish-red;
      }
    }
  }

  &__button {
    font-family: "latobold";
    font-size: 24px;
    line-height: 29px;
    color: $color-white;
    background: $color-make-a-wish-orange;
    box-shadow: 0px 4px 4px rgba(255, 181, 73, 0.3);
    border-radius: 16px;
    border: 0;
    padding: 10px 15px;

    @media (min-width: $breakpoint-lg) {
      padding: 16px 66px;
    }
  }

  &__close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 20px;
    width: 20px;
    @media (min-width: $breakpoint-xxs) {
      height: 25px;
      width: 25px;
    }
    @media (min-width: $breakpoint-lg) {
      right: 45px;
      top: 20px;
    }
    @media (min-width: $breakpoint-xl) {
      right: 40px;
      top: 20px;
    }
  }

  .country-selector {
    width: 7.5rem !important;
    flex: initial !important;
  }
}
</style>
